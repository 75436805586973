<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商家名称：">
        <el-input size="small" v-model="form.name" placeholder="请输入店铺名称"></el-input>
      </el-form-item>
      <el-form-item label="商家电话：">
        <el-input size="small" v-model="form.mobile" placeholder="请输入商家电话"></el-input>
      </el-form-item>
      <el-form-item label="运营商名称：">
        <el-input size="small" v-model="form.fa_name" placeholder="请输入运营商名称"></el-input>
      </el-form-item>
      <el-form-item label="运营商电话：">
        <el-input size="small" v-model="form.fa_mobile" placeholder="请输入运营商电话"></el-input>
      </el-form-item>
      <el-form-item label="发展商名称：">
        <el-input size="small" v-model="form.rg_name" placeholder="请输入发展商名称"></el-input>
      </el-form-item>
      <el-form-item label="发展商电话：">
        <el-input size="small" v-model="form.rg_mobile" placeholder="请输入发展商电话"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="form.status" size="small" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option label="运营商审核中" value="0"></el-option>
          <el-option label="提单中" value="1"></el-option>
          <el-option label="已通过" value="2"></el-option>
          <el-option label="已驳回" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker
          size="small"
          class="search_input"
          v-model="Registerdate"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="indexbox">
      <el-table :data="userList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="staff_name" label="商家信息" align="center">
          <template v-slot="{ row }">
            <UserInfo :name="row.store_id.store_name" :avatar="row.store_id.store_logo"></UserInfo>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="商家电话" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.mobile ? scope.row.mobile : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="所属发展商姓名" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.regimental_info ? scope.row.regimental_info.name : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="所属发展商电话" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.regimental_info ? scope.row.regimental_info.mobile : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="审核运营商姓名" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.facilitator_info.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="审核运营商电话" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="申请时间" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="审核状态" min-width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="color: orange">运营商审核中</span>
            <span v-else-if="scope.row.status == 1 && scope.row.admin_status==0" style="color: purple">提单中</span>
            <span v-if="scope.row.admin_status == 1 && scope.row.status==1" style="color: red">平台已驳回</span>
            <span v-else-if="scope.row.status==-1" style="color: red">运营商已驳回</span>
            <span v-if="scope.row.admin_status == 0 && scope.row.status==2" style="color: green">平台已通过</span>
            <span v-else-if="scope.row.status==2" style="color: green">运营商已通过</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click="getDetail(scope.row.id, 'detail_info_flag')" type="text">查看详情</el-button>
            <el-button @click="getDetail(scope.row.id, 'viewContract')" type="text" v-if="scope.row.status==2 || scope.row.admin_status == 0 && scope.row.status==2 || scope.row.status == 1 && scope.row.admin_status==0 || scope.row.admin_status == 1 && scope.row.status==1">查看合同</el-button>
            <el-button @click="getDetail(scope.row.id, 'examine_flag')" type="text" v-if="scope.row.status == 1 && scope.row.admin_status==0">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging class="paging" :total="totalNum" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </div>
    <!-- 查看详情弹窗 -->
    <el-dialog title="查看" :visible.sync="detail_info_flag" width="600px">
      <el-form label-width="120px">
        <el-form-item label="平台驳回原因" style="color: red" v-if="detailInfo.admin_status == 1 && detailInfo.status==1">{{ detailInfo.admin_remark }}</el-form-item>
        <el-form-item label="商家信息">
          <UserInfo v-if="detailInfo.store_id" :name="detailInfo.store_id.store_name" :avatar="detailInfo.store_id.store_logo"></UserInfo>
        </el-form-item>
        <el-form-item label="联系人姓名">{{ detailInfo.name }}</el-form-item>
        <el-form-item label="联系电话">{{ detailInfo.mobile }}</el-form-item>
        <el-form-item label="投流行业">{{ detailInfo.investment_industry }}</el-form-item>
        <el-form-item label="推广预算">{{ detailInfo.investment_amount }}</el-form-item>
        <el-form-item label="营业执照">
          <el-image style="width: 200px; height: 100px" :src="detailInfo.qualifications" :preview-src-list="[detailInfo.qualifications]"></el-image>
        </el-form-item>
      </el-form> 
    </el-dialog>

    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="examine_flag" width="600px">
      <el-form ref="form" label-width="120px">
        <el-form-item label="签约合同照片" v-if="detailInfo.contract">
          <div class="imageList">
            <el-image style="width: 100px; height: 50px" v-for="(item, index) in detailInfo.contract" fit="cover" :key="index" :src="item" :preview-src-list="contract"></el-image>
          </div>
        </el-form-item>
        <el-form-item label="审核结果">
          <el-radio-group v-model="params.status">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="驳回原因" v-if="params.status == -1">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="params.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examine_flag = false">取 消</el-button>
        <el-button type="primary" @click="getUpStatus">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看合同弹窗 -->
    <el-dialog title="查看合同" :visible.sync="viewContract" width="600px">
      <el-form ref="form" label-width="150px">
        <el-form-item label="签约合同照片" v-if="detailInfo.contract">
          <div class="imageList">
            <el-image style="width: 100px; height: 50px" v-for="(item, index) in detailInfo.contract" fit="cover" :key="index" :src="item" :preview-src-list="contract"></el-image>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewContract = false">取 消</el-button>
        <el-button type="primary" @click="viewContract = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
   
<script>
import _ from 'lodash';
import Paging from '@/components/paging.vue';
import UserInfo from '@/components/userInfo.vue';
export default {
  components: {
    Paging,
    UserInfo,
  },
  data() {
    return {
      realData: [],
      totalNum: {},
      form: {
        page: 1,
        rows: 10,
        name: '',
        mobile: null,
        fa_name: '',
        fa_mobile: '',
        rg_name: '',
        rg_mobile: '',
      },
      Registerdate: null,
      userList: [],

      detail_info_flag: false,
      detail_info: {},
      detail_info_stote: {},
      examine_flag: false,
      viewContract: false,

      detailInfo: {},

      params: {
        id: '',
        status: ''
      },

      contract: [],
    };
  },
  created() {
    this.getIndexatDa();
  },
  methods: {
    getIndexatDa() {
      this.contract = []
      if (this.Registerdate) {
        this.form.start_time = this.Registerdate[0] / 1000;
        this.form.end_time = this.Registerdate[1] / 1000;
      }

      this.$axios.post(this.$api.store.DouWechatList, this.form).then(res => {
        if (res.code == 0) {
          this.userList = res.result.list;
          this.totalNum = res.result.total_number;

          this.userList.forEach(item => {
            if (item.contract) {
              item.contract = JSON.parse(item.contract);
            }
          });
        }
      });
    },
    getUpStatus() {
      this.examine_flag = false;
      // if(this.params.status == '1') {
      //   delete this.params.status
      // }
      this.$axios.post(this.$api.store.DouWechatUpStatus, this.params).then(res => {
        if (res.code == 0) {
          this.getIndexatDa();
        }
      });
    },

    getDetail(id, type) {
      if (type == 'detail_info_flag') {
        this.detail_info_flag = true;
      } else if (type == 'viewContract') {
        this.viewContract = true;
      } else {
        this.examine_flag = true;
        this.params.id = id
      }

      this.getIndexatDa();
      this.detailInfo = {}

      let index = this.userList.findIndex(item => item.id == id);
      
      this.detailInfo = this.userList[index];
    },
    search() {
      this.getIndexatDa();
    },
    // 下一页
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getIndexatDa();
      } else {
        this.form.page = val;
        this.getIndexatDa();
      }
    },

    // 清空搜索
    clearSearch() {
      this.form = {
        page: 1,
        rows: 10,
        order: 'asc',
      };
      this.getIndexData();
    },
  },
};
</script>
  
<style lang="less" scoped>
.indexbox {
  font-size: 14px;

  .indexBoxLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
    background-color: #fff;

    .realtime {
      .realtimebot {
        margin-top: 20px;
        padding: 0 30px 23px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .listItem {
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          .computed {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }

          & + .listItem {
            margin-top: 20px;
          }

          .topItem {
            flex-shrink: 0;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            // padding: 12px 20px 0;
            align-items: center;

            .today {
              text-align: left;

              p {
                font-size: 28px;
                font-weight: bold;
                line-height: 30px;
              }

              p:first-child {
                white-space: nowrap;
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .indexBoxRight {
    flex: 10;
    min-width: 478px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      width: 100%;
    }

    .righttop {
      height: 388px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 8px;
      background-color: #fff;

      & > div {
        width: 100%;
        // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
      }

      .righttopT {
        height: 388px;
        padding: 16px 24px 20px 24px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;

        .titleBox {
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;

          .title {
            font-size: 14px;
            font-weight: bold;
            color: #17171a;

            &:before {
              content: '';
              height: 14px;
              border-right: 3px solid #1467ff;
              margin-right: 8px;
            }
          }

          .ignoreall {
            color: #979899;
            cursor: pointer;
          }
        }

        .listBox {
          flex: 1;
          padding-right: 10px;
          overflow: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;
          }

          .itemBox {
            display: flex;
            margin-bottom: 12px;
            justify-content: space-between;
            align-items: center;

            .content {
              width: 192px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #656566;

              span {
                display: inline-block;
                width: 4px;
                height: 4px;
                margin-bottom: 2.5px;
                background: #1467ff;
                border-radius: 50%;
                margin-right: 5px;
              }
            }

            .time {
              color: #cacbcc;
              font-size: 12px;
            }

            .todeal {
              color: #1467ff;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.charts {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}

.realtimetop {
  display: flex;
  align-items: center;

  .title {
    font-weight: bold;
    font-size: 14px;
    padding: 20px 0;

    &:before {
      content: '';
      height: 14px;
      margin-right: 8px;
    }

    span,
    i {
      color: #409eff;
    }

    span {
      margin: 0 10px;
      font-size: 15px;
    }

    i {
      transform: rotate(90deg);
      font-size: 18px;
      cursor: pointer;
    }
  }

  .timeTxt {
    margin: 0 8px 0 16px;
    color: #969699;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  .px {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 12px;
    }
  }
}

.el-main {
  background: #fff;
  .total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;
    div {
      margin-right: 40px;
    }
  }
  .sortList {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  margin-right: 16px;
}
</style>
  